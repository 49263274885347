//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PermissionConstantsRead
{
	public static LearnerProfileDataCreate: string = `read.learner_profile_data`;
	public static PersonalAnalytics: string = `read.personal_analytics`;
	public static OtherUserAnalytics: string = `read.other_users_analytics`;
	public static PersonalHistory: string = `read.personal_history`;
	public static OtherUserHistory: string = `read.other_users_history`;
	public static OtherUserAssessmentResults: string = `read.other_user_assessment_results`;
	public static GoalsMyPerfectBrain: string = `read.goals_my_perfect_brain`;
	public static BasicOthersGoalsMyPerfectBrain: string = `read.basic_other_goals_my_perfect_brain`;
	public static OtherUsersBilling: string = `read.other_users_billing`;
	public static Billing: string = `read.billing`;
	public static AggegateAnalytics: string = `read.aggregate_analytics`;
	public static CompanyUsers: string = `read.company_users`;
	public static Company: string = `read.company`;
	public static AnyCompanies: string = `read.any_companies`;
	public static Purchases: string = `read.purchases`;
	public static GetAllPurchases: string = `read.get_all_purchases`;
	public static GetPurchasesByOwnCompany: string = `read.get_purchases_by_own_company`;
	public static GetPurchasesByOwnCourses: string = `read.get_purchases_by_own_courses`;
	public static InfluencerTileMinimized: string = `read.influencer_tile_minimized`;
	public static Messages: string = `read.messages`;
	public static InfluencerTileExtended: string = `read.influencer_tile_extended`;
	public static LessonTile: string = `read.lesson_tile`;
	public static Notes: string = `read.notes`;
	public static ThreadComments: string = `read.thread_comments`;
	public static PrivateSettings: string = `read.private_settings`;
	public static ScheduleEmailSettings: string = `read.schedule_email_settings`;
	public static SystemEmailSettings: string = `read.system_email_settings`;
	public static Widgets: string = `read.widgets`;
	public static AllCourseIdsForBulkInteractions: string = `read.all_course_ids_for_bulk_interactions`;
	public static OwnCourseIdsForBulkInteractions: string = `read.own_course_ids_for_bulk_interactions`;
	public static AllCoursesForTechActions: string = `read.all_courses_for_tech_actions`;
	public static OwnCoursesForTechActions: string = `read.own_courses_for_tech_actions`;
	public static AllUsersProfileInfo: string = `read.all_users_profile_info`;
	public static CompanyUsersProfileInfo: string = `read.company_users_profile_info`;
	public static OtherAllUsersAdditionalInformation: string = `read.other_all_users_additional_information`;
	public static OtherOwnCompanyUsersAdditionalInformation: string = `read.other_own_company_users_additional_information`;
	public static AllLogHistoryActivities: string = `read.all_log_history_activities`;
	public static OwnLogHistoryActivities: string = `read.own_log_history_activities`;
	public static AllThreads: string = `read.all_threads`;
	public static ThreadsFromCms: string = `read.threads_from_cms`;
	public static AllPermissions: string = `read.all_permissions`;
	public static InfluencerAdditionalFields: string = `read.influencer_additional_fields`;
	public static AllCourseWithAllGroups: string = `read.all_course_with_all_groups`;
	public static ShowQuizResultsAfterPassage: string = `read.show_quiz_results_after_passage`;
	public static GroupCmsManagement: string = `read.group_cms_management`;
	public static MyDeskPage: string = `read.my_desk_page`;
	public static PeersPage: string = `read.peers_page`;
	public static InfluensersPage: string = `read.influensers_page`;
	public static MyDataPage: string = `read.my_data_page`;
	public static HomePage: string = `read.home_page`;
	public static CoursePage: string = `read.course_page`;
}
