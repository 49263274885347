import styles from "./styles.module.scss";
import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import { useAppStore } from "store";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import { useEffect, useState } from "react";
import { getTenantsOption } from "helpers/select.helper";
import { ITenantModel } from "models/dto/ZoomiLxp/Models/Tenants/ITenantModel";
import isNil from "lodash/isNil";
import { CloningFormType } from "store/bulk-operations/bulk-operations-store";
import CourseCloningCheckboxes from "./components/courses-cloning-form";
import UsersCloningForm from "./components/users-cloning-form";
import { ICloneCourseOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/ICloneCourseOptions";
import { ICloneUserOptions } from "models/dto/ZoomiLxp/Models/BulkOperations/CloningUsersBulkOperation/ICloneUserOptions";
import AccessWrapper from "components/partial/access-wrapper/access-wrapper";
import { PermissionConstantsCloning } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCloning";
import { TenantType } from "models/dto/ZoomiLxp/Utilities/Enumerations/TenantType";

interface IBulkCloneFormProps {
	cloningType: CloningFormType;
	className?: string;
}

const BulkCloningForm = (props: IBulkCloneFormProps) => {
	const { className, cloningType } = props;
	const { bulkOperationsStore } = useAppStore();
	const [selectedTenant, setSelectedTenant] = useState<ITenantModel>({} as ITenantModel);
	const tenantsOptions = getTenantsOption(bulkOperationsStore.tenantsList);
	const getInitialValues = () => {
		if (cloningType === CloningFormType.Users) {
			return bulkOperationsStore.usersCloningOptions;
		}
		return bulkOperationsStore.coursesCloningOptions;
	};

	useEffect(() => {
		bulkOperationsStore.getBulkOperationsTenants().then(() => {
			if (!isNil(bulkOperationsStore.currentTenantData)) {
				setSelectedTenant(bulkOperationsStore.currentTenantData);
			}
		});
	}, [bulkOperationsStore, bulkOperationsStore.currentTenantData]);

	return (
		<Formik initialValues={getInitialValues()} onSubmit={() => {}} validateOnChange={false} enableReinitialize={true}>
			{(helpers) => (
				<Form>
					<AccessWrapper
						permissions={[
							PermissionConstantsCloning.CoursesBetweenTenant,
							PermissionConstantsCloning.UsersBetweenTenants,
						]}
					>
						<DropdownList
							value={{
								value: selectedTenant?.id?.toString(),
								label: selectedTenant?.url,
							}}
							placeholder="Target tenant"
							options={tenantsOptions}
							className={classNames(styles.bulk_cloning_popup__tenant, className)}
							placeholderClassName={styles.bulk_cloning_popup__tenant_placeholder}
							isSearchable={true}
							onChange={(value) => {
								if (value) {
									helpers.setFieldValue("targetTenantId", value?.value);
									bulkOperationsStore.coursesCloningOptions = {
										...bulkOperationsStore.coursesCloningOptions,
										targetTenantId: Number(value?.value),
									};
									setSelectedTenant({ id: Number(value.value), url: value.label, name: value.label, type: value.tenantType || TenantType.Standard });
								}
							}}
							isLoading={bulkOperationsStore.isLoading}
						/>
					</AccessWrapper>
					{cloningType === CloningFormType.Courses && (
						<CourseCloningCheckboxes
							values={helpers.values as ICloneCourseOptions}
							formHelpers={helpers as FormikProps<ICloneCourseOptions>}
						/>
					)}
					{cloningType === CloningFormType.Users && (
						<UsersCloningForm
							values={helpers.values as ICloneUserOptions}
							formHelpers={helpers as FormikProps<ICloneUserOptions>}
						/>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default BulkCloningForm;
