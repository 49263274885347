import React from "react";
import { useAppStore } from "store";
import styles from "./styles.module.scss";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import Button from "components/base/button/button";
import { observer } from "mobx-react";
import classNames from "classnames";
import { infoMessages } from "constants/messages";
import { Prompt } from "react-router";
import { PermissionConstantsExport } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsExport";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";

interface HeaderDashboardRowProps {
	title: string;
	className?: string;
	onEdit?: () => void;
	onCancel?: () => Promise<void>;
	onAdd?: () => void;
	onSave?: () => Promise<void>;
}

const cx = classNames.bind(styles);

const HeaderDashboardRow = (props: HeaderDashboardRowProps) => {
	const { title, className, onEdit, onCancel, onAdd, onSave } = props;
	const { usersStore, reportsStore } = useAppStore();

	return (
		<div className={cx(styles.header_dashboard_row, className)}>
			<Prompt message={infoMessages.leaveWithoutSaving} when={reportsStore.isShowButton} />
			<div className={styles.header_dashboard_row__title}>{title}</div>
			{usersStore.checkAllUserPermissions([PermissionConstantsExport.Reports, PermissionConstantsUpdate.Reports]) && (
				<div className={styles.header_dashboard_row__box}>
					{!reportsStore.isShowButton ? (
						<EditIcon className={styles.header_dashboard_row__icon_edit} onClick={() => onEdit?.()} />
					) : (
						<div className={styles.header_dashboard_row__wrap}>
							{onCancel && (
								<Button
									className={styles.header_dashboard_row__btn_cancel}
									label="Cancel"
									onClick={() => onCancel?.()}
									type="secondary"
									size="small"
								/>
							)}
							{onAdd && (
								<Button
									type="secondary"
									className={styles.header_dashboard_row__btn_add}
									label="Add"
									onClick={() => onAdd?.()}
									size="small"
								/>
							)}
							{onSave && (
								<Button
									type="primary"
									className={styles.header_dashboard_row__btn_save}
									label="Save"
									onClick={() => onSave?.()}
									size="small"
								/>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default observer(HeaderDashboardRow);
