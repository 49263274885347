import styles from "./styles.module.scss";
import Select, { createFilter } from "react-select";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import update from "immutability-helper";
import { debounce, findIndex, isArray, uniqueId } from "lodash";
import { ReactComponent as ErrorIcon } from "assets/icons/ic_alert-circle.svg";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { defaultDropdownStyles, DropdownListProps } from "./data";

const DropdownList = ({
	className,
	options,
	placeholder,
	placeholderClassName,
	onChange,
	isError,
	errorText,
	defaultValue,
	isSearchable = false,
	isDisabled,
	value,
	querySearchParams,
	customStyles,
	onLoadAction,
	isMultiSelected,
	isLoading,
	isClearable,
	onKeyDown,
	selectInputRef,
	isCompact,
	openOnClick,
}: DropdownListProps) => {
	const [id] = useState(() => uniqueId("id-"));
	const [isSelected, setIsSelected] = useState(defaultValue ?? false);

	useEffect(() => {
		setIsSelected(!!value);
	}, [value]);

	// eslint-disable-next-line
	const onInput = (val: string) => {
		const lengthInput = val.length;
		const searchText = val.toLowerCase();
		const idx = findIndex(querySearchParams?.params.filterCriteria, {
			propertyNames: [querySearchParams?.propertyName],
		});
		if (querySearchParams && lengthInput > 0) {
			if (idx < 0) {
				const newParams = update<IQueryParams>(querySearchParams?.params, {
					filterCriteria: {
						$push: [
							{
								propertyNames: [querySearchParams?.propertyName],
								argument: searchText,
								function: FilterFunction.Like,
							} as IFilterCriterion,
						],
					},
					skip: { $set: 0 },
				});
				querySearchParams.setParams(newParams);
			} else {
				const filterItem = querySearchParams?.params.filterCriteria[idx];

				if (filterItem.argument !== searchText) {
					const updatedFilterItem = update(filterItem, { argument: { $set: searchText } });
					const newParams = update(querySearchParams?.params, {
						filterCriteria: {
							$splice: [[idx, 1, updatedFilterItem]],
						},
					});
					querySearchParams?.setParams(newParams);
				}
			}
		} else if (querySearchParams && lengthInput === 0 && idx > 0) {
			if (querySearchParams?.params?.filterCriteria?.length > 0) {
				const newParams = update<IQueryParams>(querySearchParams?.params, {
					filterCriteria: {
						$splice: [[idx, 1]],
					},
				});
				querySearchParams?.setParams(newParams);
			}
		}
	};

	const onDebouncedInput = useMemo(() => debounce(onInput, 350), [onInput]);

	useEffect(() => {
		onLoadAction?.();
		// eslint-disable-next-line
	}, []);

	const isEmptyMulti = isMultiSelected && isArray(value) && value.length;

	return (
		<div className={classNames(styles.select_field, { [styles.disabled]: isDisabled }, className)}>
			<Select
				id={id}
				defaultValue={defaultValue}
				value={value}
				options={options}
				classNamePrefix={styles.select_field}
				className={classNames(styles.select_field__control, { [styles.select_field__compact]: isCompact, [styles.select_field__is_error]: isError })}
				placeholder={!isSelected || isEmptyMulti ? placeholder : null}
				styles={customStyles ? { ...defaultDropdownStyles, ...customStyles } : defaultDropdownStyles}
				isSearchable={isSearchable}
				onChange={(option: any) => {
					onChange!(option);
					setIsSelected(!!value);
				}}
				isDisabled={isDisabled}
				onInputChange={(value) => onDebouncedInput(value)}
				filterOption={createFilter({ ignoreAccents: false })}
				isLoading={isLoading || querySearchParams?.isLoading}
				isMulti={isMultiSelected}
				isClearable={isClearable}
				onKeyDown={onKeyDown}
				ref={selectInputRef}
				openMenuOnClick={openOnClick}
			/>
			{placeholder && isSelected && !isEmptyMulti && (
				<label htmlFor={id} className={classNames(placeholderClassName, styles.select_field__label)}>
					{placeholder}
				</label>
			)}
			{isError && (
				<div className={styles.select_field__error}>
					<div className={styles.select_field__error_box}>
						<ErrorIcon className={styles.select_field__error_icon} />
					</div>
					<div className={styles.select_field__error_text}>{errorText}</div>
				</div>
			)}
		</div>
	);
};

export default DropdownList;
